import React from "react"
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import SplitModule from "../components/SplitModule"
import Library from "../components/Library"
import NextUp from "../components/NextUp"

class LocationPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="👰🤵 Location" />
        <SplitModule
          title="Location"
          content="
          <p>Both the ceremony and the reception will be held at The Hideaway.</p>
          <p>The address is: <strong>Ingleby Lane, Ticknall, Derbyshire (entrance opposite Knowle Hill Equestrian, DE73 7JQ)</strong> - <a href='https://goo.gl/maps/UY6aJKPLNcCg436a6' title='Google Maps' target='_blank'>Google Maps Link</a>.</p>
          <p>Look out for our Welcome Signs!</p>
          <p>If you’re arriving by train, the nearest station is East Midlands Parkway. If you’re driving, you’re able to park at The Hideaway.</p>
          <p>If travelling by taxi, we recommend pre-booking your taxi home.</p>"
          gosshall={true}
          fireplace={false}
        />
        <Library />
        <NextUp
          locationOne={{
            label: "Let's RSVP",
            url: "/rsvp",
          }}
        />
      </Layout>
    )
  }
}
export default LocationPage
