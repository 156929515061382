import React from "react"

function Library() {
  return (
    <div className="library bg-primary text-black text-xl pt-8 pb-4 lg:py-20">
      <div className="container-fluid flex flex-wrap">
        <div className="w-full sm:w-3/12 sm:pr-5 sm:pl-3">
          <h3 className="text-secondary text-4xl xs:text-5xl leading-tight md:text-6xl lg:text-8xl font-display mb-2 sm:mb-0 sm:transform  sm:rotate-90 sm:origin-left sm:-mt-11">
            Info
          </h3>
        </div>
        <div className="w-full sm:w-9/12 sm:pr-3 sm:pl-5 flex flex-wrap justify-between">
          <div className="w-full sm:w-1/3 md:w-1/2 mb-5 sm:mb-12">
            <p className="text-secondary font-normal font-lg uppercase">
              Hotels
            </p>
            <ul className="list-disc list-outside pl-4">
              <li className="text-secondary">
                <a
                  href="https://www.premierinn.com/gb/en/hotels/england/leicestershire/ashby-de-la-zouch/ashby-de-la-zouch.html"
                  target="_blank"
                  rel="noopener nofollow noreferrer"
                  className="text-secondary"
                >
                  Premier Inn Ashby De La Zouch – 5.8 miles
                </a>
              </li>
              <li className="text-secondary">
                <a
                  href="https://www.premierinn.com/gb/en/hotels/england/leicestershire/east-midlands-airport/east-midlands-airport.html?cid=GLBC_EASPTI"
                  target="_blank"
                  rel="noopener nofollow noreferrer"
                  className="text-secondary"
                >
                  Premier Inn East Midlands Airport – 8.3 miles
                </a>
              </li>
              <li className="text-secondary">
                <a
                  href="https://www.radissonhotels.com/en-us/hotels/radisson-blu-east-midlands-airport"
                  target="_blank"
                  rel="noopener nofollow noreferrer"
                  className="text-secondary"
                >
                  Radisson Blu Hotel East Midlands Airport – 8.5 miles
                </a>
              </li>
            </ul>
          </div>
          <div className="w-full sm:w-1/3 md:w-1/2 sm:mb-12">
            <p className="text-secondary font-normal font-lg uppercase">
              Taxis
            </p>
            <ul className=" list-disc list-outside pl-4">
              <li className="text-secondary">Chads Cars: 01332 666222</li>
              <li className="text-secondary">
                Bloors Private hire: 01283 550505
              </li>
              <li className="text-secondary">Southside Cars: 01159 141414</li>
              <li className="text-secondary">Arrow Cars: 01332 814000</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Library
