import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

function SplitModule({ title, content, gosshall, fireplace }) {
  const data = useStaticQuery(graphql`
    query {
      allFile {
        edges {
          node {
            name
            childImageSharp {
              # Specify a fluid image and fragment
              # The default maxWidth is 800 pixels
              fluid(quality: 100, maxWidth: 767) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className="split-module text-secondary relative text-xl pt-0 pb-4 sm:pt-8 lg:py-20">
      <div className="container-fluid flex flex-wrap justify-center items-center relative z-1">
        <div className="w-full sm:w-6/12 sm:pr-5 sm:pl-3 order-12 sm:order-12 px-2">
          <h2 className="text-4xl xs:text-5xl leading-tight md:text-6xl lg:text-8xl font-display mb-2 sm:mb-0">
            {title}
          </h2>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
        {data.allFile.edges && (
          <div className="w-full sm:w-6/12 mb-8 sm:pr-3 sm:pl-4 order-1 sm:order-12">
            {data.allFile.edges.map(image => {
              return (
                <>
                  {gosshall && image.node.name === "venue" && (
                    <Img
                      fluid={image.node.childImageSharp.fluid}
                      className="w-full sm:w-auto block sm:ml-4 sm:mt-auto sm:mb-auto sm:mr-3/75-"
                      alt="Holly & Olly"
                    />
                  )}
                  {fireplace && image.node.name === "fireplace" && (
                    <Img
                      fluid={image.node.childImageSharp.fluid}
                      className="w-full sm:w-auto block sm:ml-4 sm:mt-auto sm:mb-auto sm:mr-3/75-"
                      alt="Holly & Olly"
                    />
                  )}
                </>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}
export default SplitModule
